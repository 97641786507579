import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import Header from "./header"
import Footer from "./footer"

const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = props => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={data => (
        <div className="subpixel-antialiased bg-white text-gray-800 font-sans flex flex-col min-h-screen">
          <Header
            siteTitle={data.site.siteMetadata.title}
            altLang={props.altLang}
            navigation={props.navigation}
          />
          <main role="main" id="main">
            {props.children}
          </main>
          <Footer navigation={props.navigation} social={props.social} />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            disableStyles
            buttonClasses="mx-1 px-4 py-2 inline-block rounded-full transform transition duration-150 ease-in-out text-white bg-brand-turquoise hover:bg-brand-turquoise-dark font-medium"
            declineButtonClasses="mx-1 px-4 py-2 inline-block"
            containerClasses="fixed inset-x-0 text-white bg-brand-blue-dark p-3 text-sm text-center z-30"
            contentClasses="inline-block mb-2"
            enableDeclineButton
            cookieName="gatsby-gdpr-google-tagmanager"
          >
            Apex Group uses cookies.{" "}
            <Link to="/privacy" className="underline">
              See our Privacy Policy
            </Link>{" "}
            for more information about how we use these.
          </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  altLang: PropTypes.array,
  navigation: PropTypes.object,
  social: PropTypes.object,
}

export default Layout
