import React from 'react'
import PropTypes from 'prop-types'
import Card from './card'

const CardDeck = ({ slice }) => {
  var numberOfCards = slice.items.length
  return (
    <React.Fragment>
      <ul className={`component-${slice.slice_type} md:grid mt-8 gap-8 ${numberOfCards > '4' ? (`grid-cols-3 xl:grid-cols-4`):(`grid-cols-${numberOfCards}`)}`}>
        {slice.items.map((card, index) => {
          return (
            <Card fields={card} key={`card-${index}`} />
          )
        })}
      </ul>
    </React.Fragment>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CardDeck
