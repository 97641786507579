import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import CheckLink from './checkLink'


const Text = ({ slice }) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'title.html')}} />
      <div className={slice.primary.two_column ? 'lg:text-col-2 lg:gap-10' : undefined} dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} />
      {(slice.primary.button_link.url && slice.primary.button_text) && <CheckLink className='button' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>}
    </>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Text
