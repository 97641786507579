import React from 'react'
import PropTypes from 'prop-types'

const Accordion = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      {slice.primary.title && (
        <div className='text-white' dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
      )}
      {slice.primary.text && (
        <div className='text-white' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
      )}
      <div className='mt-10'>
      {slice.items.map((accordion, index) => {
        return (
          <details className='bg-white rounded overflow-hidden shadow-sm transform transition-all border mb-3' key={`accordion-${index}`}>
            <summary className='bg-white block cursor-pointer flex hover:text-brand-blue m-0 outline-none p-4 text-left text-xl w-full'>
              <div className='flex-auto' dangerouslySetInnerHTML={{__html:accordion.question.html}}/>
              <div className='text-turquoise flex items-center indicator'><svg xmlns='http://www.w3.org/2000/svg' height='30' width='30' viewBox='0 0 24 24' fill='currentColor'><path d='M0 0h24v24H0V0z' fill='none'></path><path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'></path></svg></div>
            </summary>
            <div className='text border-t p-4' dangerouslySetInnerHTML={{__html:accordion.answer.html}} />
          </details>
        )
      })}
      </div>
    </div>
  )
}

Accordion.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Accordion
