import React from 'react'
import EmblaCarousel from './embla/emblaCarousel'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const BannerGallery = ({ autoplay, delayLength, loop, slice }) => {
  return (
    <div className={`component-${slice.slice_type} relative bg-brand-blue text-white`}>
      <EmblaCarousel autoplay={false} loop={loop} delayLength={delayLength}>
        {slice.items.map((banner, index) => {
          return (
            <div className='flex h-full' key={`banner-${index}`}>
              <div className='component-banner_with_caption relative w-full flex'>
                {get(banner, 'image') && (
                  <Img className='absolute inset-0 w-full' fluid={banner.image.fluid} alt={banner.image.alt || ''} />
                )}
                <div className='lg:w-1/2 z-10 relative py-10 lg:py-16 xl:py-20 flex flex-col'>
                  <div className='bg-brand-blue opacity-75 absolute inset-0 z-0 flex-grow'></div>
                  <div className='mx-auto max-w-2-xl lg:w-1/2 px-12 md:px-8 text-center lg:text-left z-10 relative'>
                    {get(banner, 'title') && (
                      <div dangerouslySetInnerHTML={{__html:banner.title.html}} />
                    )}
                    {get(banner, 'text') && (
                      <div className='my-4' dangerouslySetInnerHTML={{__html:banner.text.html}} />
                    )}
                    {get(banner, 'button_link') && (
                      <CheckLink className='button mt-3' link={banner.button_link}>{get(banner, 'button_text') || 'Find out more'}</CheckLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </EmblaCarousel>
    </div>
  )
}

BannerGallery.defaultProps = {
  autoplay: true,
  delayLength: 8000,
  loop: false,
}

BannerGallery.propTypes = {
  autoplay: PropTypes.bool,
  delayLength: PropTypes.number,
  loop: PropTypes.bool,
  slice: PropTypes.object.isRequired,
}

export default BannerGallery
