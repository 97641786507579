import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const bannerLocationQuery = graphql`
  {
    map: file(relativePath: { eq: "apex-offices-world-map.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
const BannerLocation = props => {
  var slice = props.slice
  return (
    <StaticQuery
      query={`${bannerLocationQuery}`}
      render={data => {
        return (
          <div className={`text-white flex flex-col component-${slice.slice_type}`} style={{backgroundColor:'#233647'}}>
            <div className='p-8 pb-20 flex flex-wrap w-full max-w-6xl mx-auto'>
              <div className='w-full sm:w-1/2 font-thin'>
                <div dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
                <div className='text-lg list' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
              </div>
              <Img fluid={data.map.childImageSharp.fluid} className='w-full sm:w-1/2 mt-12 sm:mt-0' alt='map of apex locations around the world' />
            </div>
            <footer className='mt-auto pb-8 pt-20 py-4 text-center bg-gray-700'>
              <div className='flex justify-center -mt-24 lg:-mt-32 relative z-10'>
                {slice.items.map((location, index) => {
                  return (
                    <Img className='w-12 h-12 lg:h-20 lg:w-20 -mt-2 lg:mt-1 mx-1 lg:mx-3' fixed={location.location_image.fixed} alt={location.location_image.alt || ''} key={`location-${index}`} />
                  )
                })}
              </div>
              <div className='pt-6 px-4' dangerouslySetInnerHTML={{__html:slice.primary.footer.html}} />
            </footer>
          </div>
        )
      }}
    />
  )
}

BannerLocation.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerLocation
