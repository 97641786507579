import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const Hero = ({ author, publishDate, slice }) => {
  return (
    <div className={`component-${slice.slice_type} bg-white bg-cover bg-center bg-no-repeat overflow-hidden relative`}>
      {slice.primary.image && (<Img className='absolute inset-0' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt || ''} />)}
      <div className='content z-10 relative lg:w-1/2 mx-auto p-16 lg:p-24 lg:my-10 bg-brand-blue bg-opacity-75 text-white'>
        <div className='text-center'>
          <div className='mx-auto px-6 inline-block' itemProp='name' dangerouslySetInnerHTML={{__html:get(slice.primary, 'title.html')}} />
          {get(slice.primary, 'text') && (
            <div className='text-lg' dangerouslySetInnerHTML={{__html:get(slice.primary, 'text.html')}} itemProp='headline' />
          )}
          {(slice.primary.button_link && slice.primary.button_text) && (<CheckLink className='button mt-3' link={slice.primary.button_link}>{slice.primary.button_text}</CheckLink>)}
        </div>
      </div>
    </div>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
}
export default Hero
