import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'

const Card = ({ fields }) => {
  return (
    <li className='component-card flex flex-col flex-grow relative'>
      {get(fields.image, 'fluid') && (
        <div className='card-image overflow-hidden relative z-10 -mb-10'>
          <Img className='w-1/3 mx-auto' fluid={fields.image.fluid} alt={fields.image.alt} />
        </div>
      )}
      <div className='card-caption flex flex-col flex-grow sm:mx-4 px-8 pb-8 bg-white transform transition duration-150'>
        <div className='text-center font-light' dangerouslySetInnerHTML={{__html:get(fields, 'title.html')}} />
        <div className='text my-3'>
          <div dangerouslySetInnerHTML={{__html:get(fields, 'text.html')}} />
        </div>
        {fields.button_link.url && (
          <CheckLink className='button inline-block mx-auto mt-auto' link={fields.button_link}>{fields.button_text}</CheckLink>
        )}
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired,
}

export default Card
