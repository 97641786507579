import React from 'react'
import { Link } from 'gatsby'

const CheckLink = (props) => {
  return (
    <>
      {props.link.isBroken !== true && (
        props.link.link_type === 'Document' ? (
          <Link
            activeClassName={props.activeClassName}
            className={props.className}
            to={props.link.url || props.link.uid}>
              {props.children}
          </Link>
        ):(
          <a
            className={props.className}
            href={props.link.url}
            target={props.link.target}>
              {props.children}
          </a>
        )
      )}
    </>
  )
}

export default CheckLink
