import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SliceList from '../components/sliceList'
import { withPreview } from 'gatsby-source-prismic'

export const query = graphql`
  query Page($id: String, $lang: String!) {
    prismicNavigation(lang: { eq: $lang }) {
      ...PrismicNavigationFragment
    }
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
  }
`

const PageTemplate = ({ data }) => {
  const pageData = data.prismicPage.data
  const navigation = data.prismicNavigation
  return (
    <Layout altLang={data.prismicPage.alternate_languages} navigation={navigation}>
      <SEO title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      {pageData.body !== null && pageData.body.length > 0 && pageData.body.map((slice, index) => {
        return (
          <SliceList slice={slice} key={`slice-${index}`} />
        )
      })}
    </Layout>
  )
}

export default withPreview(PageTemplate)
