import React from 'react'


const Contact = () => {
  return (
    <form
      name='contact'
      method='POST'
      action='/success'
      data-netlify='true'
      data-netlify-honeypot='bot-field'
    >
      <input type='hidden' name='bot-field' />
      <input type='hidden' name='form-name' value='contact' />
      <div className='mb-4'>
        <label className='block text-lg font-medium mt-5 mb-3 text-gray-700' htmlFor='name'>Name</label>
        <input className='appearance-none border-b border-gray-700 w-full py-3 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' type='text' name='name' id='name' required />
      </div>
      <div className='mb-4'>
        <label className='block text-lg font-medium mt-5 mb-3 text-gray-700' htmlFor='email'>Email</label>
        <input className='appearance-none border-b border-gray-700 w-full py-3 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' type='email' name='email' id='email' required />
      </div>
      <div className='mb-4'>
        <label className='block text-lg font-medium mt-5 mb-3 text-gray-700' htmlFor='subject'>Subject</label>
        <input className='appearance-none border-b border-gray-700 w-full py-3 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' type='text' name='subject' id='subject' required />
      </div>
      <div className='mb-4'>
        <label className='block text-lg font-medium mt-5 mb-3 text-gray-700' htmlFor='company'>Company</label>
        <input className='appearance-none border-b border-gray-700 w-full py-3 px-5 text-gray-700 leading-tight focus:outline-none focus:ring placeholder-gray-300' type='text' name='company' id='company' placeholder='optional' />
      </div>
      <div className='mb-4'>
        <label className='block text-lg font-medium mt-5 mb-3 text-gray-700' htmlFor='phone'>Phone</label>
        <input className='appearance-none border-b border-gray-700 w-full py-3 px-5 text-gray-700 leading-tight focus:outline-none focus:ring placeholder-gray-300' type='tel' name='phone' id='phone' placeholder='optional' />
      </div>
      <div className='mb-4'>
        <label className='block text-lg font-medium mt-5 mb-3 text-gray-700' htmlFor='message'>Message</label>
        <textarea rows="4" className='appearance-none border-b border-gray-700 w-full py-3 px-5 text-gray-700 leading-tight focus:outline-none focus:ring' name='message' id='message' required></textarea>
      </div>
      <div className='mb-4 block sm:inline-block'>
        <button type='submit' className='button w-full sm:w-auto'>Send your enquiry</button>
      </div>
    </form>
  )
}

export default Contact
