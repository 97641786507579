import React, { PureComponent } from 'react'
import CheckLink from '../components/checkLink'
import Dropdown from '../components/dropdown'
import Logo from '../components/logo'
//import LanguageSelector from '../components/languageSelector'

class Header extends PureComponent {

  constructor() {
    super()
    this.state = {
      mainNavExpanded: false,
    }
    this.toggleMainNav = this.toggleMainNav.bind(this);
  }
  toggleMainNav() {
    this.setState({
      mainNavExpanded:!this.state.mainNavExpanded
    })
  }
  render() {
    const headerData = this.props.navigation.data
    if(!headerData) return null
    return (
      <header role='banner' className='relative z-20 top-0 left-0 right-0 bg-white'>
        <nav id='navigation' className='max-w-screen-xl mx-auto relative flex items-center lg:px-8'>
          <Logo />
          <button className='lg:hidden bg-white text-black opacity-75 p-2 rounded relative z-20 ml-auto mr-2' aria-expanded={this.state.mainNavExpanded} onClick={ this.toggleMainNav } onKeyDown={ this.toggleMainNav }>
            <span className='sr-only'>Menu</span>
            <svg className='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16M4 18h16'></path>
            </svg>
          </button>
          <ul className={`${this.state.mainNavExpanded === false ? ('hidden'):('absolute inset-0 bg-white rounded shadow-md flex flex-col h-screen lg:justify-center pt-24 z-10')} lg:flex lg:justify-end lg:items-center font-medium ml-auto`}>
            {headerData.nav.map((nav, index) => {
              return (
                <React.Fragment key={`nav-${index}`}>
                  {nav.items.length > 1 ? (
                    <Dropdown items={nav.items}>{nav.primary.label}</Dropdown>
                  ):(
                    <li key={`nav-${index}`} className='m-2 text-black'>
                      <CheckLink className='block py-2 px-2 text-center text-gray-500 border-b-2 border-transparent hover:text-black hover:border-brand-turquoise transition duration-150 ease-in-out' activeClassName='border-brand-turquoise text-gray-900' link={nav.primary.link} onClick={ this.toggleNav }>{nav.primary.label}</CheckLink>
                    </li>
                  )}
                </React.Fragment>
              )
            })}
            {/*this.props.altLang && (
              <LanguageSelector lang={this.props.navigation.lang} altLangs={this.props.altLang} />
            )*/}
          </ul>
        </nav>
      </header>
    )
  }
}

export default Header
