import React from "react"
import EmblaCarousel from "./embla/emblaCarousel"
import PropTypes from "prop-types"
import get from "lodash/get"
import Img from "gatsby-image"

const Image = ({ autoplay, delayLength, loop, slice }) => {
  return (
    <div className={`component-${slice.slice_type}`}>
      {slice.primary.gallery_name && (
        <div
          dangerouslySetInnerHTML={{
            __html: get(slice.primary, "gallery_name.html"),
          }}
        />
      )}
      {slice.items.length > 1 ? (
        <EmblaCarousel
          autoplay={autoplay}
          loop={loop}
          delayLength={delayLength}
          slice={slice}
        >
          {slice.items.map((item, index) => {
            return (
              <div
                className={"bg-white item" + (index === 0 ? " active" : "")}
                key={`item-${index}`}
                aria-selected={index === 0 ? "true" : "false"}
              >
                {item.image && (
                  <Img fluid={item.image.fluid} alt={item.image.alt} />
                )}
                {item.text && (
                  <div className="caption relative z-10 pb-3 px-6 w-full text-center">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: get(item, "text.html"),
                      }}
                    />
                  </div>
                )}
              </div>
            )
          })}
        </EmblaCarousel>
      ) : (
        <div className="bg-white">
          <Img
            fluid={slice.items[0].image.fluid}
            alt={slice.items[0].image.alt}
          />
          {slice.items[0].image.text && (
            <div className="caption relative z-10 pb-3 px-6 w-full text-center">
              <div
                dangerouslySetInnerHTML={{
                  __html: get(slice.items[0].image, "text.html"),
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

Image.defaultProps = {
  autoplay: false,
  delayLength: 4000,
  loop: false,
}

Image.propTypes = {
  autoplay: PropTypes.bool,
  delayLength: PropTypes.number,
  loop: PropTypes.bool,
  slice: PropTypes.object.isRequired,
}

export default Image
