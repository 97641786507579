import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Img from 'gatsby-image'


const Headshots = ({ slice }) => {
  return (
    <React.Fragment>
      <div className='text-white px-8 py-10'>
        {get(slice.primary, 'title') && (
          <div className='mb-10' dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
        )}
        <ul className={`component-${slice.slice_type} grid grid-cols-2 md:grid-cols-3 gap-16 lg:px-16 lg:mb-8`}>
          {slice.items.map((individual, index) => {
            return (
              <React.Fragment key={`individual-${index}`}>
                <div className='text-center w-full md:w-48 mx-auto'>
                  <div className='rounded-full overflow-hidden border-4 border-brand-turquoise mb-6'>
                    <Img fluid={individual.image.fluid} alt={individual.image.alt || ''} />
                  </div>
                  <div className='flex flex-col justify-center -mx-8'>
                    <div className='text-xl mt-1 font-medium'>{individual.name}</div>
                    <div>{individual.role}</div>
                  </div>
                </div>
              </React.Fragment>
            )
          })}
        </ul>
      </div>
    </React.Fragment>
  )
}

Headshots.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Headshots
