import React from 'react'
import PropTypes from 'prop-types'
import CheckLink from '../components/checkLink'
import Img from 'gatsby-image'
import get from 'lodash/get'


const BannerWithCaption = ({ slice }) => {
  return (
    <div className={`component-banner_with_caption relative sm:flex ${get(slice.primary, 'dark_background') ? 'inverse':'normal'} ${get(slice.primary, 'image_position') ? 'left':'right flex-row-reverse'}`}>
      {get(slice.primary, 'image') && (
        <div className={`flex flex-col justify-center sm:w-1/2 w-full ${get(slice.primary, 'image_position') ? 'left':'right'}`}>
          <Img
            fluid={{...slice.primary.image.fluid, aspectRatio: 16 / 9}}
            alt={slice.primary.image.alt || ''}
          />
        </div>
      )}
      <div className='sm:w-1/2 z-10 relative md:pt-8 pb-10 lg:pt-20 lg:pb-24 flex flex-col'>
        <div className='mx-auto px-8 md:px-16 text-left z-10 relative'>
          {get(slice.primary, 'title') && (
            <div className='title' id={slice.primary.title.text.split(' ').join('-').toLowerCase()} dangerouslySetInnerHTML={{__html:slice.primary.title.html}} />
          )}
          {get(slice.primary, 'text') && (
            <div className='my-4' dangerouslySetInnerHTML={{__html:slice.primary.text.html}} />
          )}
          {get(slice.primary, 'button_link.url') && (
            <CheckLink className='button mt-3' link={slice.primary.button_link}>{get(slice.primary, 'button_text') || 'Find out more'}</CheckLink>
          )}
        </div>
      </div>
    </div>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
