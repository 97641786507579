import React from 'react'
import PropTypes from 'prop-types'
import Accordion from './accordion'
import Assetflow from './assetflow'
import Auction from './auction'
import BannerGallery from './bannerGallery'
import BannerLocation from './bannerLocation'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Counter from './counter'
import Headshots from './headshots'
import Hero from './hero'
import Image from './image'
import InternalNavigation from './internalNavigation'
import Quote from './quote'
import Statistics from './statistics'
import TestimonialGallery from './testimonialGallery'
import Text from './text'
import Video from './video'

const SliceList = ({ slice }) => {
  return (
    <>
      {slice.slice_type === 'auction' && (
        <Auction />
      )}
      {slice.slice_type === 'asset_flow' && (
        <div className='max-w-7xl w-full mx-auto p-4 md:p-8 mb-8'>
          <Assetflow slice={slice} />
        </div>
      )}
      {slice.slice_type === 'accordion' && (
        <div className='bg-gradient-to-r from-brand-blue to-brand-blue-light'>
          <div className='max-w-4xl w-full mx-auto p-4 md:p-8 mb-8'>
            <Accordion slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'banner_gallery' && (
        <div className='max-w-screen-xl mx-auto xl:px-8 pb-4 mb-8'>
          <BannerGallery autoplay loop delayLength={5000} slice={slice} />
        </div>
      )}
      {slice.slice_type === 'banner_locations' && (
        <div className='max-w-screen-xl mx-auto'>
          <BannerLocation slice={slice} />
        </div>
      )}
      {slice.slice_type === 'banner_with_caption' && (
        <div className='max-w-screen-xl w-full mx-auto xl:px-8 banner_with_caption'>
          <BannerWithCaption slice={slice} />
        </div>
      )}
      {slice.slice_type === 'card_deck' && (
        <div className='max-w-7xl w-full mx-auto mb-8 px-4 xl:px-8'>
          <CardDeck slice={slice} />
        </div>
      )}
      {slice.slice_type === 'contact' && (
        <div className='max-w-2xl mx-auto px-4 xl:px-8 mb-8'>
          <Contact />
        </div>
      )}
      {slice.slice_type === 'counter' && (
        <div className='max-w-screen-xl mx-auto px-4 xl:px-8 mb-8'>
          <Counter slice={slice} />
        </div>
      )}
      {slice.slice_type === 'headshots' && (
        <div className='max-w-screen-xl mx-auto bg-gradient-to-r from-brand-blue to-brand-blue-light'>
          <div className='max-w-6xl mx-auto xl:px-8'>
            <Headshots slice={slice} />
          </div>
        </div>
      )}
      {slice.slice_type === 'hero' && (
        <div className='max-w-screen-xl mx-auto xl:px-8 mb-8'>
          <Hero slice={slice} />
        </div>
      )}
      {slice.slice_type === 'image_gallery' && (
        <div className='max-w-screen-xl w-full mx-auto mb-8'>
          <Image autoplay delayLength={6000} slice={slice} />
        </div>
      )}
      {slice.slice_type === 'internal_navigation' && (
        <div className='max-w-4xl mx-auto px-4'>
          <InternalNavigation slice={slice} />
        </div>
      )}
      {slice.slice_type === 'quote' && (
        <div className='max-w-screen-md mx-auto my-12 px-8 sm:px-6 md:flex md:flex-col py-12 md:py-16 mb-8'>
          <Quote slice={slice} />
        </div>
      )}
      {slice.slice_type  === 'statistics' && (
        <Statistics slice={slice} />
      )}
      {slice.slice_type === 'testimonial_gallery' && (
        <div className='max-w-screen-xl mx-auto xl:px-8 py-4'>
          <TestimonialGallery autoplay loop delayLength={5000} slice={slice} />
        </div>
      )}
      {slice.slice_type === 'text_with_button' && (
        <div className={`mx-auto px-8 pb-8 md:px-16 my-8 component-text_with_button ${slice.primary.two_column ? ('max-w-7xl w-full '):('max-w-4xl')}`}>
          <Text slice={slice} />
        </div>
      )}
      {slice.slice_type === 'video' && (
        <div className='max-w-screen-xl w-full mx-auto p-4 md:p-8 mb-8'>
          <Video slice={slice} />
        </div>
      )}
    </>
  )
}

SliceList.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default SliceList
