import React from 'react'
import CheckLink from '../components/checkLink'
import { graphql, StaticQuery } from 'gatsby'
import get from 'lodash/get'
import Img from 'gatsby-image'

let newDate = new Date()
let year = newDate.getFullYear();

const footerQuery = graphql`
  {
    apexInverse: file(relativePath: { eq: "apex_logo-white.png" }) {
      childImageSharp {
        fixed(height: 44) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const Footer = ( props ) => {
  const footerData = props.navigation.data
  if(!footerData) return null
  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={data => (
        <footer role='contentinfo' className='bg-brand-blue border-t-4 border-brand-blue-dark mt-8 font-medium mt-auto'>
          <div className='max-w-screen-xl mx-auto p-8'>
            <div className='grid grid-cols-3 col-x-8'>
              {footerData.body.map((footer, index) => {
                return (
                  <div key={`footer-${index}`} className='col-span-2 md:col-span-1 '>
                    <ul>
                    {footer.items.map((footerItem, index) => {
                      return (
                        <li className='flex' key={`footerItem-${index}`}>
                          <div className='py-2 text-orange-600 mr-3 font-black'><i className='text-brand-turquoise fa fa-chevron-right' aria-hidden='true'></i></div>
                          <CheckLink className='py-2 md:pb-0 block text-white hover:text-brand-turquoise transform transition duration-150 ease-in-out' link={footerItem.link}>{footerItem.link_label}</CheckLink>
                        </li>
                      )
                    })}
                    </ul>
                  </div>
                )
              })}
            </div>
            <div className='sm:mt-20 mt-10'>
              <Img fixed={data.apexInverse.childImageSharp.fixed} alt={footerData.company_display_name} className='mx-1' loading='lazy' />
              <span className="sr-only">{footerData.company_display_name}</span>
            </div>
          </div>
          <div className='bg-brand-blue-dark text-center sm:text-left'>
            <div className='max-w-screen-xl px-8 mx-auto text-base font-light text-white pt-3 pb-6'>Copyright &copy; {year} - {get(footerData, 'company_display_name')}</div>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer
