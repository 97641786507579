import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'


const InternalNavigation = ({ slice }) => {
  return (
    <ul className={`component-${slice.slice_type} hidden lg:flex flex-row justify-between font-medium text-center p-3`}>
      {slice.items.map((link, index) => {
        return (
          <li key={`card-${index}`} className='flex-auto'>
            <a className='block py-1 hover:text-brand-turquoise' href={get(link, 'link_location')}>
              <i className='text-brand-turquoise fa fa-chevron-right mr-3' aria-hidden='true'></i>{get(link, 'link_name')}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

InternalNavigation.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default InternalNavigation
