import React, { Component } from 'react'

function scripting() {
  return {__html: `
    <script>
      window.bidjs = {
        config: {
          apiBase: '//lewes.eu-west-2.bidjs.com/auction-007/api',
          clientId: '${process.env.GATSBY_BIDJS_CLIENT_ID}',
          googleMapsApiKey: 'AIzaSyDNMejtwjaIEGjJhg67_uQF9ZLZpvjQX4o',
          staticBase: '//lewes.eu-west-2.bidjs.com/static/3',
          staticCDN: 'https://static.bidjs.com',
          staticVersion: '3',
          templateVariant: 'bootstrap3',
          websocketBase: 'wss://broadcast.eu-west-2.bidjs.com'
        },
        modules: {
          auctionDetails: '#!/auctionDetails/%AUCTION_ID%',
          auctionsArchived: true,
          invoices: true,
          lotDetails: '#!/itemDetails/%AUCTION_ID%/%ITEM_ID%',
          mySales: true,
          webcast: true,
          login: true, //please note that as of Modules v1.7 this must be set to true. Prior versions can specify a page such as #!/login
          register: true
        },
        options: {
          allowTitleChange: true,
          allowMetaDescriptionChange: true,
          scrollOnNavigationElementId: '___gatsby',
          standalone: false,
          unsupportedBrowserMessage: 'Your browser is not supported. Please use a browser such as Google Chrome or Microsoft Edge to ensure full functionality',
          analyticsCode: 'UA-641770-1',
          analyticsNamespace: 'app',
          imagePadColor: 'rgb:ffffff',
          imageCropMode: 'pad'
          //defaultModule: 'empty' //only use this when you want to display login status and nav buttons with no BidJS software. Read more here: https://support.bidlogix.net/support/solutions/articles/44001812889-installing-bidjs-using-dependencies-on-non-bidjs-pages
        },
        callback: function (event) {
          switch (event.action) {
            case 'BIDJS_AUTHENTICATED':
              if (window.toggleLoggedInState) window.toggleLoggedInState(true)
            break
            case 'BIDJS_AUTHENTICATION_REMOVED':
              if (window.toggleLoggedInState) window.toggleLoggedInState(false)
            break
            case 'BIDJS_NAVIGATED':
            case 'BIDJS_MODULES_NAVIGATED':
              if (window.toggleNavState) window.toggleNavState(event.data.path.split('/')[1].replace('Secure?',''))
            break
          }
        }
      }
    </script>
    <script>
      if (typeof window !== 'undefined') {
        window.addEventListener('DOMContentLoaded', function() {
          (function($) {
            $(document).ready(function() {
              PageApp.start();
            });
          })(jQuery);
        });
        var loadDeferredStyles = function() {
          var addStylesNode = document.getElementById('deferred-styles');
          var replacement = document.createElement('div');
          replacement.innerHTML = addStylesNode.textContent;
          document.body.appendChild(replacement)
          addStylesNode.parentElement.removeChild(addStylesNode);
        };
        var raf = requestAnimationFrame || mozRequestAnimationFrame ||
            webkitRequestAnimationFrame || msRequestAnimationFrame;
        if (raf) raf(function() { window.setTimeout(loadDeferredStyles, 0); });
        else window.addEventListener('load', loadDeferredStyles);
      }
    </script>
  `};
}

class Auction extends Component {

  componentDidMount() {
    const script=document.createElement('script')
    script.src='https://static.bidjs.com/3/bootstrap3/js/bidjs-modules.chunk.js'
    script.async=true
    this.instance.appendChild(script)
  }

  render () {
    return (
      <div className='pt-5 text-inverse'>
        <noscript id='deferred-styles'>
          <link href='https://lewes.eu-west-2.bidjs.com/static/3/prettyphoto/jquery.prettyPhoto.min.css' rel='stylesheet' type='text/css' />
        </noscript>

        <div id='bidlogix-app' className='bidlogix-app' />

        <div className='bidlogix-app'>
          <div id='bidlogix-modal' />
        </div>

        <div className='bidlogix-app'>
          <div id='bidjs' className='max-w-screen-xl mx-auto px-4' />
        </div>

        <div dangerouslySetInnerHTML={scripting()} />
        <div ref={el => (this.instance = el)} className='modules' />
      </div>
    )
  }
}

export default Auction
