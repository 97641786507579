import React from 'react'
import Img from 'gatsby-image'
import { graphql, StaticQuery } from 'gatsby'

const assetFlowQuery = graphql`
  {
    part1x1: file(relativePath: { eq: "assetflow/services-infographic1_part1x1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part1x2: file(relativePath: { eq: "assetflow/services-infographic1_part1x2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part1x3: file(relativePath: { eq: "assetflow/services-infographic1_part1x3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part1x4: file(relativePath: { eq: "assetflow/services-infographic1_part1x4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part1x5: file(relativePath: { eq: "assetflow/services-infographic1_part1x5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part1x6: file(relativePath: { eq: "assetflow/services-infographic1_part1x6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part1x7: file(relativePath: { eq: "assetflow/services-infographic1_part1x7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part2x1: file(relativePath: { eq: "assetflow/services-infographic1_part2x1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part2x2: file(relativePath: { eq: "assetflow/services-infographic1_part2x2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part2x3: file(relativePath: { eq: "assetflow/services-infographic1_part2x3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part2x4: file(relativePath: { eq: "assetflow/services-infographic1_part2x4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part2x5: file(relativePath: { eq: "assetflow/services-infographic1_part2x5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part2x6: file(relativePath: { eq: "assetflow/services-infographic1_part2x6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part2x7: file(relativePath: { eq: "assetflow/services-infographic1_part2x7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part3x1: file(relativePath: { eq: "assetflow/services-infographic1_part3x1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part3x2: file(relativePath: { eq: "assetflow/services-infographic1_part3x2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part3x3: file(relativePath: { eq: "assetflow/services-infographic1_part3x3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part3x4: file(relativePath: { eq: "assetflow/services-infographic1_part3x4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part3x5: file(relativePath: { eq: "assetflow/services-infographic1_part3x5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part3x6: file(relativePath: { eq: "assetflow/services-infographic1_part3x6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    part3x7: file(relativePath: { eq: "assetflow/services-infographic1_part3x7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Assetflow = ( props ) => {
  return (
    <StaticQuery
      query={`${assetFlowQuery}`}
      render={data => {
        return (
          <div className='grid grid-cols-7 gap-0'>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part1x1.childImageSharp.fluid} alt='' /></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part1x2.childImageSharp.fluid} alt='' /></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part1x3.childImageSharp.fluid} alt='' /></div>
            <div><a href='/services/#policy-and-strategy' aria-labelledby='policy-and-strategy'><Img className='w-full h-full p-0 m-0' fluid={data.part1x4.childImageSharp.fluid} alt='' /></a></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part1x5.childImageSharp.fluid} alt='' /></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part1x6.childImageSharp.fluid} alt='' /></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part1x7.childImageSharp.fluid} alt='' /></div>
            <div><a href='/services/#procurement' aria-labelledby='procurement'><Img className='w-full h-full p-0 m-0' fluid={data.part2x1.childImageSharp.fluid} alt='' /></a></div>
            <div><a href='/services/#valuation' aria-labelledby='valuation'><Img className='w-full h-full p-0 m-0' fluid={data.part2x2.childImageSharp.fluid} alt='' /></a></div>
            <div><a href='/services/#finance' aria-labelledby='finance'><Img className='w-full h-full p-0 m-0' fluid={data.part2x3.childImageSharp.fluid} alt='' /></a></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part2x4.childImageSharp.fluid} alt='' /></div>
            <div><a href='/services/#redeployment' aria-labelledby='redeployment'><Img className='w-full h-full p-0 m-0' fluid={data.part2x5.childImageSharp.fluid} alt='' /></a></div>
            <div><a href='/services/#end-of-lease-management' aria-labelledby='end-of-lease-management'><Img className='w-full h-full p-0 m-0' fluid={data.part2x6.childImageSharp.fluid} alt='' /></a></div>
            <div><a href='/services/#retirement-and-disposal' aria-labelledby='retirement-and-disposal'><Img className='w-full h-full p-0 m-0' fluid={data.part2x7.childImageSharp.fluid} alt='' /></a></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part3x1.childImageSharp.fluid} alt='' /></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part3x2.childImageSharp.fluid} alt='' /></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part3x3.childImageSharp.fluid} alt='' /></div>
            <div><a href='/services/#service-level-and-support' aria-labelledby='service-level-and-support'><Img className='w-full h-full p-0 m-0' fluid={data.part3x4.childImageSharp.fluid} alt='' /></a></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part3x5.childImageSharp.fluid} alt='' /></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part3x6.childImageSharp.fluid} alt='' /></div>
            <div><Img className='w-full h-full p-0 m-0' fluid={data.part3x7.childImageSharp.fluid} alt='' /></div>
          </div>
        )
     }}
    />
  )
}

export default Assetflow
