import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import Img from 'gatsby-image'

const Quote = ({ slice }) => {

  return (
    <blockquote className={`component-${slice.slice_type} md:flex-grow md:flex md:flex-col relative`}>
      <svg className='absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-400 opacity-75' fill='currentColor' viewBox='0 0 32 32'>
        <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z'></path>
      </svg>
      <div className='relative text-3xl font-medium text-white md:flex-grow'>
        <div dangerouslySetInnerHTML={{__html:get(slice.primary, 'quote.html')}} />
      </div>
      <footer className='flex items-center mt-8 mb-3'>
        <div className='flex-shrink-0 inline-flex rounded-full border-2 border-white'>
        {get(slice.primary.image, 'fluid') && (
          <div className='overflow-hidden transition duration-500 transform'>
            <Img className='h-24 w-24 rounded-full bg-indigo-500' fluid={slice.primary.image.fluid} alt={slice.primary.image.alt} />
          </div>
        )}
        </div>
        <div className='ml-4'>
          <p className='text-base font-medium text-white'>{get(slice.primary, 'author')}</p>
          <p className='text-sm font-medium text-indigo-200'>{get(slice.primary, 'role') && <span>{get(slice.primary, 'role')}</span>}{(get(slice.primary, 'company') && get(slice.primary, 'role')) && ','} {get(slice.primary, 'company') && <span> {get(slice.primary, 'company')}</span>}</p>
        </div>
      </footer>
    </blockquote>
  )
}

Quote.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Quote
