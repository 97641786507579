import React from 'react';
import get from 'lodash/get'
import EmblaCarousel from './embla/emblaCarousel';
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'


const testimonialQuery = graphql`
  {
    testimonial00: file(relativePath: { eq: "testimonials/richards-bay-minerals.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial01: file(relativePath: { eq: "testimonials/cat-logo.png" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial02: file(relativePath: { eq: "testimonials/hanson_logo.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial03: file(relativePath: { eq: "testimonials/transalta_logo.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial10: file(relativePath: { eq: "testimonials/briggs-stratton-logo.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial11: file(relativePath: { eq: "testimonials/ge_logo.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial12: file(relativePath: { eq: "testimonials/rolls-royce-symbol.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial13: file(relativePath: { eq: "testimonials/eaton_logo.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial20: file(relativePath: { eq: "testimonials/pratt_whitney_logo.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial21: file(relativePath: { eq: "testimonials/bp_helios.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial22: file(relativePath: { eq: "testimonials/shell_logo_.png" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    testimonial23: file(relativePath: { eq: "testimonials/conocophillips-logo.jpg" }) {
      childImageSharp {
        fixed(height: 110) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
const TestimonialGallery = ({ autoplay, delayLength, loop, slice }) => {
  return (
    <StaticQuery
      query={`${testimonialQuery}`}
      render={data => {
        return (
          <div className={`component-${slice.slice_type} bg-white relative`}>
            <div className='w-full text-center'>
              <div className='text-brand-blue' dangerouslySetInnerHTML={{__html:get(slice.primary, 'title.html')}} />
            </div>
            <EmblaCarousel autoplay={autoplay} loop={loop} delayLength={delayLength}>
              {slice.items.map((item, testimonialIndex) => {
                const numberOfImages = ['0', '1', '2', '3']; // this all needs re-writing
                return (
                  <div className='text-center max-w-screen-lg mx-auto p-8' key={`item-${testimonialIndex}`}>
                    <blockquote>
                      <p className='text-lg mb-5'>{item.quote}</p>
                      <footer className='text-brand-blue-light font-normal'>{item.author} - {item.role}, {item.company}</footer>
                    </blockquote>
                    <div className='mt-16 mb-8'>
                      {numberOfImages.map((image, imageIndex) => {
                        var testimonial = 'testimonial' + testimonialIndex + imageIndex
                        if (get(data[testimonial], 'childImageSharp.fixed')) {
                          var testimonialImage = data[testimonial].childImageSharp.fixed
                        }
                        return (
                          <Img fixed={testimonialImage} className='mx-4' key={`item-${imageIndex}`} alt='' role='presentation' />
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </EmblaCarousel>
          </div>
        )
      }}
    />
  )
}

TestimonialGallery.defaultProps = {
  autoplay: true,
  delayLength: 4000,
  loop:false,
}

TestimonialGallery.propTypes = {
  autoplay: PropTypes.bool,
  delayLength: PropTypes.number,
  loop: PropTypes.bool,
  slice: PropTypes.object.isRequired,
}

export default TestimonialGallery
