import React, { PureComponent } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const logoQuery = graphql`
  {
    logo: file(relativePath: { eq: "ApexGroup_Logo.png" }) {
      childImageSharp {
        fixed(width: 277) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;

class Logo extends PureComponent {
  render() {
    return (
      <StaticQuery
        query={`${logoQuery}`}
        render={data => {
          return (
            <a href='/' className='block mt-1 overflow-hidden sm:w-auto sm:overflow-auto relative z-30'><Img fixed={data.logo.childImageSharp.fixed} alt='Apex Group logo' /><span className='sr-only'>Apex Group</span></a>
          )
        }}
      />
    )
  }
}

export default Logo
